<template>
  <div>
    <util-date-type-picker />

    <div class="row">
      <div class="col-xl-4">
        <div class="cui__utils__heading">
          <strong>Total Cards Sent</strong>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="font-weight-bold font-size-70 text-primary text-center">{{calcTotalCardsSent}}</div>
            <span v-html="displayBlank"></span>
          </div>
        </div>
      </div>

      <div class="col-xl-4">
        <div class="cui__utils__heading">
          <strong>Percentage of Minimum</strong>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="font-weight-bold font-size-70 text-primary text-center">
              <span v-html="calcPercentageAchieved"></span>
            </div>
            <span v-html="displayBlank"></span>
          </div>
        </div>
      </div>

      <div class="col-xl-4">
        <div class="cui__utils__heading">
          <strong>Minimum and Contract date</strong>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="font-weight-bold font-size-70 text-primary text-center">{{displayClientMinimum}}</div>
            <span v-html="displayDateRange"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="cui__utils__heading">
      <strong>Client Minimum</strong>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="position-relative">
          <vue-chartist
            class="chartist-animated height-600 mt-4"
            type="Line"
            :data="animationData"
            :options="animatonOptions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueChartist from 'v-chartist'
import DateTypePicker from './DateTypePicker'
import ChartistTooltip from 'chartist-plugin-tooltips-updated'
import ChartistLegend from 'chartist-plugin-legend'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'ClientMinimum',
  components: {
    'vue-chartist': VueChartist,
    'util-date-type-picker': DateTypePicker,
  },
  data: function () {
    const calcTotalCardsSent = 0
    const calcPercentageAchieved = '-'
    const displayClientMinimum = '-'
    const displayDateRange = '&nbsp;'
    const displayBlank = '&nbsp;'

    const animationData = {
      labels: [],
      series: [],
    }

    const animatonOptions = {
      axisY: {
        onlyInteger: true,
      },
      plugins: [ChartistTooltip({ anchorToPoint: false, appendToBody: true, seriesName: false }), ChartistLegend()],
    }

    return {
      animationData,
      animatonOptions,
      calcTotalCardsSent,
      calcPercentageAchieved,
      displayClientMinimum,
      displayDateRange,
      displayBlank,
    }
  },
  computed: {
    ...mapState('admin', [
      'orderedProductsDetails',
      'globalYear',
      'globalClientSelected',
      'globalDateTypeSelected',
    ]),
    ...mapGetters('admin', [
      'clientMinimum',
    ]),
  },
  methods: {
    ...mapActions('admin', [
      'fetchOrderedProductDetails',
    ]),
    ...mapMutations('admin', [
      'startLoading',
      'stopLoading',
    ]),

    triggerUpdateChart() {
      this.startLoading()

      let fromDate = this.globalYear.fromDate
      let toDate = this.globalYear.toDate
      if (this.clientMinimum.enabled && this.clientMinimum.startDate && this.clientMinimum.endDate) {
        fromDate = (new Date(this.clientMinimum.startDate)).toISOString()
        toDate = (new Date(this.clientMinimum.endDate)).toISOString()
      }

      Promise.all([
        this.fetchOrderedProductDetails({
          saveToStateObj: 'setOrderedProductsDetails',
          selectFilter: '(count,total,hits.(data.(creation_date,product_items.(product_id,quantity))))',
          dateType: this.globalDateTypeSelected,
          fromDate: fromDate,
          toDate: toDate,
        }),
      ])
        .then(() => this.updateChart(this.getOrderedProductsByMonth(this.orderedProductsDetails), this.clientMinimum))
        .then(() => this.stopLoading())
    },

    updateChart(orderList, clientMinimum) {
      const actualOrdered = []
      let remainingGoal = 0
      actualOrdered.push(remainingGoal)
      for (let i = 1; i <= 12; i++) {
        remainingGoal = remainingGoal + orderList[i].qty
        actualOrdered.push(remainingGoal)
      }
      this.calcTotalCardsSent = remainingGoal

      // Burndown Quantity
      const expectedOrders = []
      if (clientMinimum.enabled && clientMinimum.value > 0) {
        this.displayClientMinimum = clientMinimum.value
        this.calcPercentageAchieved = (((this.calcTotalCardsSent * 100) / this.displayClientMinimum).toFixed(1)) + '<sup class="font-size-40">%</sup>'

        if (clientMinimum.startDate && clientMinimum.endDate) {
          const startDate = new Date(clientMinimum.startDate)
          const endDate = new Date(clientMinimum.endDate)
          this.displayDateRange = "<div class='font-size-16 text-center text-muted'> " +
            (startDate.getUTCMonth() + 1) + '/' + startDate.getUTCDate() + '/' + startDate.getUTCFullYear() + ' - ' +
            (endDate.getUTCMonth() + 1) + '/' + endDate.getUTCDate() + '/' + endDate.getUTCFullYear() +
            '</ div>'
        } else {
          this.displayDateRange = '&nbsp;'
        }

        for (let i = 12; i >= 0; i--) {
          expectedOrders.push(clientMinimum.value)
        }
      } else {
        this.calcPercentageAchieved = '-'
        this.displayClientMinimum = '-'
        this.displayDateRange = "<div class='font-size-16 text-center text-muted'>All orders from " + this.globalYear.year + '</ div>'
      }

      this.animationData = {
        labels: ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        series: [
          actualOrdered,
          expectedOrders,
        ],
      }
    },

    getOrderedProductsByMonth(orderedDetails) {
      const orderedProducts = orderedDetails || []
      const groupOrders = []
      for (let i = 1; i <= 12; i++) {
        groupOrders[i] = { orderMonth: i, qty: 0 }
      }

      // Group orders by month
      orderedProducts.reduce((res, value) => {
        const creationMonth = this.getOrderMonth(value.data.creation_date)
        const prodItems = value.data.product_items || []
        prodItems.forEach((prodItem) => {
          if (!res[creationMonth]) {
            res[creationMonth] = { orderMonth: creationMonth, qty: 0 }
            groupOrders[creationMonth] = res[creationMonth]
          }
          res[creationMonth].qty += prodItem.quantity
        })
        return res
      }, {})

      return groupOrders
    },

    getOrderMonth(date) {
      return this.formatUTCtoLocal(date)?.split('/')[0]
    },
    formatUTCtoLocal(utc) {
      return (new Date(utc))?.toLocaleDateString()
    },
  },

  mounted() {
    this.triggerUpdateChart()
  },

  watch: {
    globalClientSelected() {
      this.triggerUpdateChart()
    }
  },
}
</script>
<style lang="scss" module>
.ct-legend {
    position: relative;
    z-index: 10;

    li {
        position: relative;
        padding-left: 23px;
        margin-bottom: 3px;
    }

    li:before {
        width: 12px;
        height: 12px;
        position: absolute;
        left: 0;
        content: '';
        border: 3px solid transparent;
        border-radius: 2px;
    }

    li.inactive:before {
        background: transparent;
    }

    &.ct-legend-inside {
        position: absolute;
        top: 0;
        right: 0;
    }
}
</style>
